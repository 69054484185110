import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { width, minWidth, space as styledSpace } from 'styled-system'
import { space, colors, fontSizes, fontWeights, radius, COLOR_CONSTANTS } from 'theme'
import { pxToRem } from 'helpers'
import { Box, Flex } from './Layout'
import ErrorLabel from './ErrorLabel'
import { Text } from './Typography'

const StyledInput = styled.input`
  padding: ${space.m};
  height: ${({ isSmall, isLarge }) => (isSmall ? pxToRem(30) : isLarge ? pxToRem(50) : pxToRem(40))};
  border: 1px solid ${({ isError }) => (isError ? colors.error : COLOR_CONSTANTS.WHITE)};
  border-radius: ${({ borderRadius }) => borderRadius};
  color: ${({ color }) => color || colors.primaryText};
  font-size: ${fontSizes.s};
  ${({ hasBoxShadow }) => hasBoxShadow && `box-shadow: 0px 20px 40px rgba(39, 40, 49, 0.1);`}
  :focus {
    outline-style: none;
  }
  ::placeholder {
    color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
    font-size: ${fontSizes.s};
    font-weight: ${fontWeights.normal};
  }
  ${width}
  ${minWidth}
  ${styledSpace}
`

const Input = ({
  label,
  secondaryLabel,
  iconComp,
  error,
  errorColor,
  iconPosition,
  hasBoxShadow,
  borderRadius,
  ...props
}) => (
  <Flex flexDirection="column" position="relative">
    {label && (
      <Text mb="xs" ml="xs" fontSize="m">
        {label}
      </Text>
    )}
    {secondaryLabel && (
      <Text mb="s" fontSize="xs" color="secondaryText">
        {secondaryLabel}
      </Text>
    )}

    <Flex flexDirection="column">
      <Flex alignItems="center" position="relative">
        <StyledInput hasBoxShadow={hasBoxShadow} borderRadius={borderRadius} {...props} />
        {iconComp && (
          <Box position="absolute" {...iconPosition}>
            {iconComp}
          </Box>
        )}
      </Flex>
      {error && <ErrorLabel color={errorColor} mt="xxs" textAlign="left" error={error} />}
    </Flex>
  </Flex>
)

Input.defaultProps = {
  iconPosition: { right: '10px' },
  hasBoxShadow: false,
  borderRadius: radius.l,
  iconComp: null,
  label: '',
  error: '',
  errorColor: undefined,
}

Input.propTypes = {
  iconComp: PropTypes.node,
  iconPosition: PropTypes.object,
  label: PropTypes.string,
  hasBoxShadow: PropTypes.bool,
  borderRadius: PropTypes.string,
  errorColor: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Input
