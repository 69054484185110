import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, height, maxWidth, maxHeight, display, position } from 'styled-system'

const StyledImg = styled.img`
  object-fit: ${({ objectFit }) => objectFit || 'contain'};
  ${width}
  ${height}
  ${maxWidth}
  ${maxHeight}
  ${space}
  ${display}
  ${position}
`

const Image = ({ source, alt, ...props }) => <StyledImg src={source} alt={alt} loading="lazy" {...props} />

Image.defaultProps = {
  alt: '',
}

Image.propTypes = {
  source: PropTypes.string,
  alt: PropTypes.string,
}

export default Image
