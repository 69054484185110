import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSearchBox } from 'react-instantsearch-hooks-web'
import { minWidth, space as styledSpace, width } from 'styled-system'
import { space, colors, fontSizes, radius, COLOR_CONSTANTS } from 'theme'
import { pxToRem } from 'helpers'
import LocalImage from 'components/LocalImage'
import Input from 'components/Input'
import { Flex } from 'components/Layout'

import search from 'static/svg/search.svg'

import { Text } from 'components/Typography'
import { Box } from '../Layout'

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
`

const StyledIconWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

const StyledInput = styled.input`
  padding: ${space.s};
  border: none;
  color: ${colors.primaryText};
  font-size: ${fontSizes['3xl']};
  :focus {
    outline-style: none;
  }
  ::placeholder {
    color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
    font-size: ${fontSizes['3xl']};
  }
  ${width}
  ${minWidth}
  ${styledSpace}
  height: ${pxToRem(52)};
  border-radius: ${radius.xxl};
  border-color: transparent;
`

const StyledImage = styled(LocalImage)`
  cursor: pointer;
`

const SearchBox = ({ onFocus, onChange }) => {
  const { query, refine } = useSearchBox()

  return (
    <Flex flexDirection="column" width="100%">
      <StyledForm>
        <Flex position="relative" width="100%">
          <StyledIconWrapper>
            <StyledImage src={search} width="24px" height="24px" />
          </StyledIconWrapper>
          <StyledInput
            value={query}
            onChange={(e) => {
              refine(e.target.value)
              onChange(e.target.value)
            }}
            width="100%"
            placeholder="Search"
            pl="40px"
            hasBoxShadow
            borderRadius={radius.m}
            onFocus={onFocus}
          />
        </Flex>
      </StyledForm>
    </Flex>
  )
}

SearchBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
}

export default SearchBox
