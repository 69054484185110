import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space, colors, radius } from 'theme'
import { Text } from 'components/Typography'
import Button from 'components/Button'

const ButtonLink = styled(Button.Secondary)`
  display: flex;
  text-decoration: none;
  padding: ${space.xs} ${space.s};
  margin-right: ${space.s};
  margin-top: ${space.s};
  background-color: ${({ color }) => transparentize(0.85, color)};
  text-transform: uppercase;
  border-radius: ${radius.l};
  height: fit-content;
`

const CATEGORY_COLORS = {
  'digital-marketing': colors.primary,
  'social-media': colors.primary,
  'content-marketing': colors.primary,
  sales: colors.primary,
  seo: colors.primary,
  facebook: '#6446EC',
  instagram: '#6446EC',
  linkedin: '#6446EC',
  pinterest: '#6446EC',
  twitter: '#6446EC',
  'company-news': '#00B427',
  'success-stories': '#00B427',
  recommendations: '#00A7A7',
  productivity: '#00A7A7',
  'customer-retention': '#00A7A7',
  reputation: '#00A7A7',
  uncategorized: colors.secondaryText,
}

const CategoryItem = ({ category, buttonStyle, ...textProps }) => {
  const { slug, name } = category

  const color = CATEGORY_COLORS[slug]

  return (
    <ButtonLink as="a" href={`/insights/${slug}`} key={slug} color={color || colors.secondaryText} style={buttonStyle}>
      <Text fontSize="xxs" color={color} fontWeight="medium" {...textProps}>
        {name}
      </Text>
    </ButtonLink>
  )
}

CategoryItem.defaultProps = {
  buttonStyle: {},
}

CategoryItem.propTypes = {
  category: PropTypes.object.isRequired,
  buttonStyle: PropTypes.object,
}

export default CategoryItem
