import { createGlobalStyle } from 'styled-components'
import { fluidRange } from 'polished'
import { pxToRem } from 'helpers'

export const fontStack = 'Poppins, sans-serif'

export const COLOR_CONSTANTS = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  SALUTE: '#18191F',
  COSMIC_ENERGY: '#9193A9',
  SEMI_BLACK: '#1A1A1A',
  FLUORESCENT_RED: '#E83C51',
  ZHEN_ZHU_BAI_PEARL: '#F5F8FD',
  FLAT_BLUE: '#3077B0',
  SOLITUDE: '#80838C',
  GLACIER_PEARL: '#D6D7E1',
  BOYZONE: '#2482FD',
  RED: '#FF0000',
  ROCKMAN_BLUE: '#4AA1EB',
  AMERICANA: '#0077B5',
  JUNGLE_GREEN: '#27AE60',
  ALGAE: '#55AA68',
  VIOLET_WHIMSEY: '#D6D7E0',
  CARPACCIO: '#E34234',
  BIO_BLUE: '#0B60DA',
  BRIGHT_NAVY_BLUE: '#417EE0',
  VENUS_MIST: '#60616C',
  ICEWIND_DALE: '#E9ECEF',
  RED_STAR: '#FA5252',
  DENIM: '#18191F',
  COBALT: '#0063E3',
  NAVY: '#002E69',
  SKY: '#2482FD',
  SAPPHIRE: '#6D7C8F',
  AEGEAN: '#4A6B96',
  CAROLINA: '#455A64',
  SPRUCE: '#203247',
  CHIFFON: '#E5E5E5',
  SALT: '#EAF3FF',
  LACE: '#D3E6FF',
  DAISY: '#F5F8FD',
  BONE: '#C4C4C4',
  COTTON: '#E0E3E8',
  CARROT: '#FFF9C8',
  PUMPKIN_PIE: '#E69C59',
  PALE_CORNFLOWER_BLUE: '#B6CEF0',
  BABY_BLUE_EYES: '#A6C7F5',
  CITYSCAPE: '#4E545C',
}

export const colors = {
  primary: COLOR_CONSTANTS.COBALT,
  primaryText: COLOR_CONSTANTS.NAVY,
  secondaryText: COLOR_CONSTANTS.BLACK,
  ternaryText: COLOR_CONSTANTS.VENUS_MIST,
  white: COLOR_CONSTANTS.WHITE,
  black: COLOR_CONSTANTS.BLACK,
  error: COLOR_CONSTANTS.FLUORESCENT_RED,
}

export const radius = {
  s: '2px',
  m: '4px',
  l: '6px',
  xl: '8px',
  xxl: '16px',
  xxxl: '24px',
  circle: '50%',
  pill: '9999px',
}

export const space = {
  xxs: pxToRem(2),
  xs: pxToRem(4),
  s: pxToRem(8),
  sm: pxToRem(12),
  m: pxToRem(16),
  xm: pxToRem(24),
  l: pxToRem(32),
  xl: pxToRem(64),
  xxl: pxToRem(128),
  xxxl: pxToRem(256),
}

export const fontSizes = {
  xxs: pxToRem(10),
  xs: pxToRem(12),
  s: pxToRem(14),
  m: pxToRem(16),
  l: pxToRem(18),
  xl: pxToRem(20),
  xxl: pxToRem(24),
  xxxl: pxToRem(32),
  '3xl': pxToRem(36),
  '4xl': pxToRem(42),
  '5xl': pxToRem(46),
  '6xl': pxToRem(52),
}

export const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 600,
  extraBold: 700,
}

export const lineHeights = {
  xs: 1,
  s: 1.2,
  m: 1.3,
  l: 1.4,
  xl: 1.5,
  xxl: 1.6,
  xxxl: 1.7,
  xxxxl: 1.8,
  xxxxxl: 1.9,
  xxxxxxl: 2.0,
}

export const GlobalStyles = createGlobalStyle`
  html, body, #___gatsby {
    height:100%;
  }
  
  html {
    ${fluidRange({
      prop: 'font-size',
      fromSize: '14px',
      toSize: '18px',
    })}
    overflow-x: hidden;
  }
  body {
    font-family: ${fontStack};
    font-weight: 400;
    color: ${colors.primaryText};
    background-color: ${COLOR_CONSTANTS.WHITE};
    min-width: 320px;
  }
  body,
  button,
  input,
  select {
    font-family: ${fontStack};
  }
  button {
    font-family: inherit;
    font-size: ${fontSizes.m};
    background-color: transparent;
  }
  button {
    border: none;
    margin: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
  }
  input[type='radio'] {
    visibility: hidden;
    position: absolute;
  }
  input[type='radio'] + label {
    display: flex;
    position: relative;
  }
  input[type='radio'] + label:before {
    display: flex;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 2px;
    vertical-align: baseline;
    content: ' ';
    border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  }
  input[type='radio']:checked + label:before {
    background: ${colors.white};
    border: 5px solid ${colors.primary};
  }
  input[type='radio'] + label:before {
    border-radius: ${radius.pill};
  }
  a {
    cursor: pointer;
    text-decoration: none;
    color: ${colors.primary};
  }
  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding:0;
  }
  .slick-dots li::marker, .slick-dots li button {
    content: '';
    display: none;
   }
   .slick-dots li.slick-active div {
    background-color: ${colors.primary};
   }
  .slick-initialized .slick-slide {
    display: inline-block !important;
  }
  .slick-slide{
    float: none !important;
  }
  .slick-arrow {
    display: none !important;
  }
  .css-17rus17-ValueContainer{
    padding-left: 0px !important;
  }
  .grecaptcha-badge { 
    visibility: hidden; 
  }
  .primary-text {
    color: ${colors.primary};
  }
  .rc-slider-rail {
   background-color: rgba(11, 96, 218, 0.2) !important;
  }
  .rc-slider-track {
    background-color: ${COLOR_CONSTANTS.BIO_BLUE} !important;
  }
  .rc-slider-handle, .rc-slider-handle:active, .rc-slider-handle:hover,.rc-slider-handle-click-focused:focus {
    border:none !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3) !important;
    width: 24px !important;
    height: 24px !important;
    margin-top: -10px !important;
  }
  .transition {
    transition: 0.5s;
  }
  .post h2 {
    font-size:${fontSizes.xxl};
    font-weight: bold;  
  }
  .post h3 {
    font-size:${fontSizes.xl};
    font-weight: bold;  
  }
  .post h4 {
    font-size:${fontSizes.l};
    font-weight: bold;  
  } 
  .carousel-menu-item {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin: auto;
    transition: 0.3s opacity, 0.5s transform;
  }
  
  .carousel-menu-item.is-active .carousel-item-menu_rounded_box {
    opacity: 1;
    width: 28px;
    height: 28px;
    transition: 0.3s opacity, 0.3s width, 0.3s height;
  }

  .carousel-menu-item.is-active .carousel-item-menu_check_mark {
    opacity: 1; 
    transition: 0.3s opacity; 
  }

  .carousel-menu-item.is-active .carousel-item-menu_text {
    transition: 0.3s opacity, 0.3s font;
    opacity: 1;  
    font-size: ${fontSizes.xxxl}; 
  }

  .slides {
    transition: 1s;
  } 
   
  .giphy-embed {
    margin: 0 auto;
    max-width: 100%;
    width: 100%; 
  }
  .twitter-tweet {
    margin-left: auto;
    margin-right: auto;
  }
  .twitter-tweet iframe {
    width: 100% !important;
  }
  .join-template_button {
    cursor: pointer;
    outline: none;
    align-items: center;
    justify-content: center;
    user-select: none;
    height: ${pxToRem(56)}; 
    transition: background-color 0.12s ease-in-out,border-color 0.12s ease-in-out,color 0.15s ease-in-out;
    display: flex;
    text-decoration: none;
    border: 1px solid ${COLOR_CONSTANTS.DAISY};
    background: linear-gradient(90deg,#0b60da 0%,#2482fd 100%);
    border-radius: ${radius.l};
    color: ${COLOR_CONSTANTS.WHITE} !important;
    font-size: ${fontSizes.m}; 
    font-weight: ${fontWeights.medium};
    padding-left: ${space.l};
    padding-right: ${space.l};
    &:hover {
      background: ${COLOR_CONSTANTS.WHITE} ;
      border: 1px solid ${colors.primary};
      color: ${colors.primary} !important;
    }
  }
  .shadyside-close svg {
    color: #404A52;
  }
  .instagram-embed-wrapper {
    margin: auto;
    max-width: 500px !important;
    min-height: auto;
  }

  .instagram-embed-container {
    width:100%;
    text-align:center;
    margin: auto;
    max-width: 500px !important;
    min-height: auto;
  }

  .instagram-media {
    border: 1px solid #d3d3d3!important;
    border-radius: 3px;
    max-width: 500px!important;
    min-height: auto;   
    max-height: 830px; 
  }
  #credential_picker_iframe {
    outline: none;
  }
  #credential_picker_container {
    outline: none;
    top: 65px !important;
  }
`

export const breakpoints = ['900px']
breakpoints.mobile = '0'
breakpoints.mobileLarge = '500px'
breakpoints.tablet = '768px'
breakpoints.desktop = '1024px'
breakpoints.desktopWide = '1366px'
breakpoints.desktopLarge = '1600px'

// This is needed for styled-system to work
export const theme = {
  colors,
  space,
  fontSizes,
  fontWeights,
  breakpoints,
  lineHeights,
}
