import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'components/Typography'
import { COLOR_CONSTANTS } from 'theme'

const ErrorLabel = ({ error, color = COLOR_CONSTANTS.FLUORESCENT_RED, ...props }) => (
  <Text color={color} {...props}>
    {error}
  </Text>
)

ErrorLabel.propTypes = {
  error: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default ErrorLabel
