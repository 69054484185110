import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, colors, COLOR_CONSTANTS, radius } from 'theme'
import LocalImage from './LocalImage'
import { Flex } from './Layout'
import { Text } from './Typography'
import Button from './Button'
import clearIcon from '../static/svg/clear_signup_google.svg'
import logo from '../static/svg/logo.svg'

const StyledGoogleSignedUpPopup = styled(Flex)`
  position: fixed;
  z-index: 9999;
`

const StyledGoogleSignedUpBody = styled(Flex)`
  border-radius: ${radius.xl};
  background: ${COLOR_CONSTANTS.WHITE};
  box-shadow: 0 0 3px 1px rgba(66, 133, 244, 0.2);
  padding-bottom: 14px;
  transition: border 280ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  flex-direction: column;
`

const StyledIconWrapper = styled(Flex)`
  transition: transform 0.05s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #202124;
  border-radius: ${radius.pill};
  height: 40px;
  left: 4px;
  top: 4px;
  opacity: 0.04;
  position: absolute;
  transform-origin: center;
  transform: scale(0);
  width: 40px;
`

const StyledCloseButtonWrapper = styled(Flex)`
  margin-left: ${space.xs};
  padding: 12px;
  position: relative;
  cursor: pointer;
  outline: none;
  &:hover ${StyledIconWrapper} {
    transform: scale(1);
  }
`

const StyledCloseImage = styled(LocalImage)`
  position: relative;
`

const StyledContentWrapper = styled(Flex)`
  flex-direction: column;
  transition: max-height 250ms;
  overflow: hidden;
  position: relative;
  border-top: 1px solid #dadce0;
  padding: ${space.xs} ${space.m} 0 ${space.m};
`

const StyledCloseText = styled(Text)`
  cursor: pointer;
  color: ${colors.primary};
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${colors.primary};
  }
`

const StyledLinkText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${colors.secondaryText};
`

const StyledLogoImage = styled(LocalImage)`
  border-radius: ${radius.pill};
`

const StyledButtonGradient = styled(Button.Gradient)`
  width: 100%;
`

const GOOGLE_MODAL_ID = 'google-prompt-body'
const GoogleSignupPopup = ({ handleDismiss }) => {
  const listenToClickOutside = (event) => {
    const isClosest = event.target.closest(`#${GOOGLE_MODAL_ID}`)

    if (!isClosest) {
      handleDismiss()
    }
  }

  useEffect(() => {
    window.addEventListener('click', listenToClickOutside)
    return () => window.removeEventListener('click', listenToClickOutside)
  }, [])

  const action = 'Start Your Free Trial'

  return (
    <StyledGoogleSignedUpPopup
      top={{ mobile: 'unset', tablet: '65px' }}
      right={{ mobile: 'unset', tablet: '20px' }}
      bottom={{ mobile: 0, tablet: 'unset' }}
      left={{ mobile: 0, tablet: 'unset' }}
      width={{ mobile: '100%', tablet: '398px' }}
      px={{ mobile: 0, tablet: '8px' }}
      pt={{ mobile: 0, tablet: '6px' }}
      pb={{ mobile: 0, tablet: '10px' }}
      id={GOOGLE_MODAL_ID}
    >
      <StyledGoogleSignedUpBody>
        <Flex alignItems="center" justifyContent="flex-start" height="48px" width="100%">
          <Flex alignItems="center" flexGrow="1" pl="m">
            <StyledLogoImage source={logo} width="20px" height="20px" />
            <Text ml="12px" fontWeight="bold" fontSize="s" color="secondaryText">
              Continue to Vista Social
            </Text>
          </Flex>
          <StyledCloseButtonWrapper onClick={handleDismiss}>
            <StyledIconWrapper />
            <StyledCloseImage src={clearIcon} width="24px" height="24px" />
          </StyledCloseButtonWrapper>
        </Flex>
        <StyledContentWrapper>
          <Text mt="14px" color="primaryText">
            Start scheduling amazing posts on all social media platforms that drive actual results!
          </Text>
          <Flex mt="m" alignItems="center" flexDirection="column">
            <StyledButtonGradient
              onClick={() => {
                window.location.href = '/additional'
              }}
            >
              <Text>{action}</Text>
            </StyledButtonGradient>
            <StyledCloseText mt="s" onClick={handleDismiss}>
              Not Today
            </StyledCloseText>
          </Flex>
          <Text mt="m" color="secondaryText" fontSize="xs">
            Click “{action}” to agree to Vista Social’s{' '}
            <StyledLinkText as="a" href="/terms" target="_blank" color="secondaryText" fontSize="xs">
              Terms of Service
            </StyledLinkText>{' '}
            and acknowledge that Vista Socials’s{' '}
            <StyledLinkText as="a" href="/privacy" target="_blank" color="secondaryText" fontSize="xs">
              Privacy Policy
            </StyledLinkText>{' '}
            applies to you.
          </Text>
        </StyledContentWrapper>
      </StyledGoogleSignedUpBody>
    </StyledGoogleSignedUpPopup>
  )
}

GoogleSignupPopup.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
}
export default GoogleSignupPopup
