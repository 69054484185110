import React from 'react'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, COLOR_CONSTANTS } from 'theme'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Link from 'components/Link'
import { Flex } from 'components/Layout'
import clear from 'static/svg/clear.svg'

const Wrapper = styled(Flex)`
  position: fixed;
  border-radius: 3px;
  padding: ${space.m};
  bottom: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLOR_CONSTANTS.WHITE};
  width: 100%;
  z-index: 10;
  box-shadow: 0px 4px 10px rgb(39 40 49 / 30%);
  transition: transform 0.3s ease-in-out;
  transform: translateY(${({ isAlertBarOpen }) => (isAlertBarOpen ? -45 : 0)}px);
  &:hover {
    background-color: ${COLOR_CONSTANTS.ICEWIND_DALE};
  }
`

const StyledButton = styled.button`
  margin-left: ${space.xs};
  padding: ${space.s};
  outline: 0;
`

const StyledLink = styled(Link)`
  display: inline-block;
`

const IS_COOKIES_ACCEPTED = 'isCookiesAccepted'
const COOKIE_EXPIRATION_DAYS = 365

class CookieNotice extends React.Component {
  state = { isVisible: false }
  componentDidMount = async () => {
    const isCookiesAccepted = await Cookies.get(IS_COOKIES_ACCEPTED)
    this.setState({ isVisible: !isCookiesAccepted })
  }
  handleAcceptCookiesConsent = () => {
    Cookies.set(IS_COOKIES_ACCEPTED, true, { expires: COOKIE_EXPIRATION_DAYS })
    this.setState({ isVisible: false })
  }

  render() {
    const { isVisible } = this.state
    if (!isVisible) {
      return null
    }

    const { isAlertBarOpen } = this.props

    return (
      <Wrapper
        maxWidth={{ mobile: '100%', tablet: '305px' }}
        m={{ mobile: 0, tablet: '24px' }}
        isAlertBarOpen={isAlertBarOpen}
      >
        <Text fontSize="xs" textAlign={{ mobile: 'center', tablet: 'left' }}>
          By continuing to use this site you consent to the use of cookies in accordance with&nbsp;
          <StyledLink href="/privacy">
            <Text as="span" fontSize="xs" color="primary">
              our cookie policy
            </Text>
          </StyledLink>
          .
        </Text>
        <StyledButton onClick={this.handleAcceptCookiesConsent} type="button" aria-label="Close">
          <LocalImage src={clear} />
        </StyledButton>
      </Wrapper>
    )
  }
}

CookieNotice.propTypes = {
  isAlertBarOpen: PropTypes.bool.isRequired,
}

export default CookieNotice
