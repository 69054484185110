import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, useInstantSearch } from 'react-instantsearch-hooks-web'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { Box, Flex } from 'components/Layout'
import LocalImage from 'components/LocalImage'
import { Text } from 'components/Typography'
import search from 'static/svg/search.svg'
import clear from 'static/svg/clear.svg'
import SearchBox from './SearchBox'
import SearchResult from './SearchResult'

import CategoryItem from '../../templates/components/CategoryItem'

const StyledIconWrapper = styled(Box)`
  cursor: pointer;
`

const StyledDialogOverlay = styled(DialogOverlay)`
  background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
  z-index: 2147483001;
`

const StyledDialogContent = styled(DialogContent)`
  position: relative;
  width: 100% !important;
  border-radius: ${radius.s};
  ${styledSpace};
  padding: 0 !important;
  margin: 0 auto !important;
`

const StyledSearchWrapper = styled(Box)`
  width: 100%;
  position: absolute;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 100vh;
  max-width: 100%;
  outline: none;
  background-color: ${COLOR_CONSTANTS.WHITE};
`

const StyledSearchFormWrapper = styled(Flex)`
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: ${space.xl};
`

const indices = [{ name: `gatsby`, title: `Pages` }]

const NoResultsBoundary = ({ children, fallback }) => {
  const { results } = useInstantSearch()

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned yet.
  // if (!results.__isArtificial && results.nbHits === 0) {
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    )
  }

  return children
}

const NoResults = () => {
  return (
    <Box mt="xl">
      <Text textAlign="center" fontSize="2xl" color="primaryText">
        No results found
      </Text>
    </Box>
  )
}

const categories = [
  {
    slug: 'content-marketing',
    name: 'Content Marketing',
  },
  {
    slug: 'digital-marketing',
    name: 'Digital Marketing',
  },
  {
    slug: 'social-media',
    name: 'Social Media',
  },
  {
    slug: 'success-stories',
    name: 'Success Stories',
  },
]

const BlogSearcherComponent = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [query, setQuery] = useState()
  const searchClient = useMemo(
    () => algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY),
    []
  )
  // console.log(popularCategories)

  // TODO:: save search query when closing modal

  return (
    <Box position="relative">
      <StyledIconWrapper
        onClick={() => {
          setIsSearchOpen(true)
        }}
      >
        <LocalImage src={search} />
      </StyledIconWrapper>

      <StyledDialogOverlay
        isOpen={isSearchOpen}
        onDismiss={() => {
          setIsSearchOpen(false)
        }}
      >
        <Box width="100%">
          <StyledDialogContent aria-label="modal">
            <StyledSearchWrapper>
              <Box maxWidth={CONTAINER_MAX_WIDTH} mx="auto" px="l">
                <Box width="100%" position="relative" py="xl">
                  <InstantSearch searchClient={searchClient} indexName={indices[0].name}>
                    <StyledSearchFormWrapper width="100%" alignItems="center" justifyContent="space-between">
                      <SearchBox onChange={(query) => setQuery(query)} />
                      <StyledIconWrapper
                        ml="l"
                        onClick={() => {
                          setIsSearchOpen(false)
                        }}
                      >
                        <LocalImage src={clear} width="24px" height="24px" />
                      </StyledIconWrapper>
                    </StyledSearchFormWrapper>
                    <NoResultsBoundary fallback={<NoResults />}>
                      <SearchResult show={query && query.length > 0} indices={indices} />
                      {!query && categories && categories.length > 0 && (
                        <Flex flexDirection="column" mt="l">
                          <Text fontSize="l" color={COLOR_CONSTANTS.DENIM}>
                            Recommend topics
                          </Text>
                          <Flex mt="s" width="100%" justifyContent="flex-start" flexWrap="wrap">
                            {categories.map((category, index) => (
                              <CategoryItem key={index} category={category} fontSize="s" />
                            ))}
                          </Flex>
                        </Flex>
                      )}
                    </NoResultsBoundary>
                  </InstantSearch>
                </Box>
              </Box>
            </StyledSearchWrapper>
          </StyledDialogContent>
        </Box>
      </StyledDialogOverlay>
    </Box>
  )
}

BlogSearcherComponent.defaultProps = {}

BlogSearcherComponent.propTypes = {}

export default BlogSearcherComponent
