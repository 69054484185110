import React from 'react'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, colors } from 'theme'
import LocalImage from 'components/LocalImage'
import Link from 'components/Link'
import { Flex } from 'components/Layout'
import clear from 'static/svg/clear_white.svg'

// purple background // background-color: #a538f5;
const Wrapper = styled(Flex)`
  position: fixed;
  border-radius: 3px;
  height: 45px;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;

  background: linear-gradient(180deg, #1285fe 0, #0065cf);
  width: 100%;
  z-index: 10;
  box-shadow: 0 -0.25rem 0.3125rem rgb(0 0 0 / 5%);
  backdrop-filter: blur(0.5rem);
  transition: all 0.3s ease;
  &:hover {
    background-color: #7f4afa;
  }
`

const StyledButton = styled.button`
  margin-left: ${space.xs};
  padding: ${space.s};
  outline: 0;
  width: 45px;
  height: 100%;
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${colors.white};
`

// const DATA = {
//   isCookiesAccepted: 'snapchat_integration',
//   cookieExpirationDays: 365,
//   text: 'Snapchat Stories and Spotlights direct scheduling (without reminders) is finally here!  Learn more!',
//   link: 'https://vistasocial.com/insights/best-snapchat-scheduling-tool-vista-social/',
// }

const DATA = {
  isCookiesAccepted: 'bluesky',
  cookieExpirationDays: 365,
  text: "🦋 BLUESKY IS HERE! It's time to own your content and audience on decentralized social media.",
  link: 'https://vistasocial.com/insights/vista-social-supports-bluesky/',
}

class AlertBar extends React.Component {
  state = { isVisible: false }
  componentDidMount = async () => {
    const isCookiesAccepted = await Cookies.get(DATA.isCookiesAccepted)
    this.setState({ isVisible: !isCookiesAccepted })
    if (!isCookiesAccepted) {
      this.props.setIsAlertBarOpen(true)
    }
  }
  handleAcceptCookiesConsent = () => {
    Cookies.set(DATA.isCookiesAccepted, true, { expires: DATA.cookieExpirationDays })
    this.setState({ isVisible: false })
    this.props.setIsAlertBarOpen(false)
  }

  render() {
    const { isVisible } = this.state
    if (!isVisible) {
      return null
    }
    return (
      <Wrapper>
        <StyledLink as="a" href={DATA.link} fontSize="s" textAlign="center" px="m">
          {DATA.text}
        </StyledLink>
        <StyledButton onClick={this.handleAcceptCookiesConsent} type="button" aria-label="Close">
          <LocalImage src={clear} />
        </StyledButton>
      </Wrapper>
    )
  }
}

AlertBar.propTypes = {
  setIsAlertBarOpen: PropTypes.func.isRequired,
}

export default AlertBar
