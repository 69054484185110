/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, title, image, type, author, path = '/' }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const metaImage =
    image && image.indexOf('http') === 0 ? image : `${process.env.GATSBY_SITE_URL}${image || '/img/vistasocial.jpg'}`

  const metaTitle = title ? `${title}${title.includes('Vista Social') ? '' : ' | Vista Social'}` : 'Vista Social'
  const metaType = type || 'website'

  const canonical = `${process.env.GATSBY_SITE_URL}${path}${path.endsWith('/') ? '' : '/'}`

  return (
    <React.Fragment>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify([
            {
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Vista Social',
              legalName: 'Vista Social LLC',
              url: 'https://vistasocial.com/',
              logo: 'https://vistasocial.com/img/logo_small.png',
              foundingDate: '2021',
              alternateName: 'VistaSocial',
              sameAs: [
                'https://facebook.com/vistasocialapp',
                'https://twitter.com/vistasocialapp',
                'https://linkedin.com/company/vistasocial',
                'https://instagram.com/vistasocialapp',
                'https://youtube.com/@vistasocialapp',
              ],
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'Customer Support',
                email: 'info@vistasocial.com',
              },
            },
          ]),
        }}
      />

      <Helmet
        htmlAttributes={{ lang }}
        title={metaTitle}
        link={[
          {
            rel: `canonical`,
            href: canonical,
          },
        ]}
        meta={[
          {
            name: `author`,
            content: author,
          },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: metaType,
          },
          {
            property: `og:image:secure_url`,
            content: metaImage,
          },
          {
            property: `og:image`,
            content: metaImage,
          },
          // { property: `og:image:width`, content: '400' },
          // { property: `og:image:height`, content: '225' },
          { property: 'og:image:alt', content: metaTitle },
          {
            property: `og:url`,
            content: canonical,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            property: `fb:app_id`,
            content: `4525852984100537`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: metaImage,
          },
        ].concat(meta)}
      />

      <script async src="https://www.instagram.com/embed.js" />
    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  author: PropTypes.string,
  path: PropTypes.string,
}

export default SEO
