import { rem } from 'polished'
import Img from 'gatsby-image'
import he from 'he'
import moment from 'moment-timezone'
import placeholderImg from './static/images/blog_placeholder.png'

export const pxToRem = (pxVal) => rem(pxVal, 18)
export const stripHtml = (string) => string.replace(/<[^>]*>?/gm, '')
export const parseTextFromServer = (string) => stripHtml(he.decode(string))
export const getImageByFeaturedMedia = (featuredMedia) => {
  if (featuredMedia) {
    const { alt_text: alt, title, source_url } = featuredMedia
    return { WrapperComp: 'img', featuredImageProps: { alt: alt || title, src: source_url || placeholderImg } }
  }
  return { WrapperComp: 'img', featuredImageProps: { alt: 'Vista Social', src: placeholderImg } }
}

export const formatToNumberWithCommas = (number) => {
  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const mapValueToReactSelect = ({ id, name, description }) => ({ value: id, label: name || description })
export const mapArrayToReactSelect = (arr) => arr.map(mapValueToReactSelect)

export const initializeArrayWithRange = (end, start = 0) =>
  Array.from({ length: end + 1 - start }).map((v, i) => i + start)

export const countArticleReadingTime = ({ text = '' }) => {
  const WPM = 265
  const words = text.trim().split(/\s+/).length
  return Math.ceil(words / WPM)
}

export const truncate = (string, maxChar) => {
  if (string && string.length > maxChar) return `${string.substring(0, maxChar)}${maxChar ? '...' : ''}`
  else return string
}

export const getTimeZones = () => {
  const offsetTmz = []
  const countries = moment.tz.countries()
  countries.forEach((country) => {
    const timeZones = moment.tz.zonesForCountry(country)
    timeZones.forEach((zone) => {
      const time = moment.tz(zone).format('Z')
      offsetTmz.push({
        value: zone,
        label: `(GMT ${time}) ${zone.replace(/_/g, ' ')}`,
        time: Number(time.split(':')[0]),
      })
    })
    offsetTmz.sort((a, b) => (a.time < b.time ? -1 : a.time > b.time ? 1 : 0))
  })
  return offsetTmz
}

export const getTimeZone = ({ defaultTz = 'Etc/UTC' } = {}) => {
  const timezone = getTimeZones().find(({ value }) => value === moment.tz.guess()) || null

  if (timezone) {
    return timezone.value
  } else {
    return defaultTz
  }
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))