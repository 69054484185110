import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Highlight, Hits, Index, Snippet, PoweredBy, useStats } from 'react-instantsearch-hooks-web'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { Flex, Box } from 'components/Layout'
import Link from 'components/Link'
import { Text } from 'components/Typography'

const StyledSearchResult = styled(Box)`
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: ${space.s};
  width: 100%;
  padding: ${space.m};
  border-radius: ${radius.l};
  background: ${COLOR_CONSTANTS.WHITE};

  .ais-Highlight-highlighted {
    color: ${colors.primary};
  }

  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;

    svg {
      width: 130px;
    }
  }
`

const StyledHits = styled(Hits)`
  ol {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${space.l};
    list-style: none;
    margin: 0;
    padding: 0;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  li.ais-Hits-item {
    padding: ${space.m} 0;

    .ais-Snippet-highlighted,
    .ais-Highlight-highlighted {
      color: ${COLOR_CONSTANTS.DENIM};
    }

    .ais-Snippet {
      color: ${colors.secondaryText};
    }

    .ais-Highlight-highlighted {
      background: #f8a145;
      border-radius: ${radius.m};
    }

    .ais-Snippet-highlighted {
      background: ${COLOR_CONSTANTS.CARROT};
      border-radius: ${radius.m};
    }
  }
`

const StyledTextLink = styled(Link)`
  display: flex;
  flex-direction: column;
  &:hover {
    .title {
      text-decoration: underline;
      text-decoration-color: ${COLOR_CONSTANTS.DENIM};
    }
  }
`

const HitCount = () => {
  const { nbHits } = useStats()

  return nbHits > 0 ? (
    <Flex justifyContent="flex-end">
      <Text fontSize="m" color={COLOR_CONSTANTS.DENIM}>
        {nbHits} result{nbHits !== 1 ? 's' : ''}
      </Text>
    </Flex>
  ) : null
}

const PageHit = ({ hit }) => {
  return (
    <StyledTextLink href={hit.slug}>
      <Text fontSize="l" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" className="title">
        <Highlight attribute="title" hit={hit} />
      </Text>
      <Text fontSize="s">
        <Snippet attribute="excerpt" hit={hit} />
      </Text>
    </StyledTextLink>
  )
}

PageHit.propTypes = {
  hit: PropTypes.object.isRequired,
}

const SearchResult = ({ indices, show }) => (
  <StyledSearchResult display={show ? 'block' : 'none'} width={{ mobile: 'auto', tablet: '100%' }}>
    {indices.map((index) => (
      <Index key={index.name} indexName={index.name}>
        {/*<HitCount />*/}
        <StyledHits hitComponent={PageHit} className="styled-hits-class" />
      </Index>
    ))}

    <PoweredBy />
  </StyledSearchResult>
)

SearchResult.defaultProps = {
  indices: [],
  show: false,
}

SearchResult.propTypes = {
  indices: PropTypes.array,
  show: PropTypes.bool,
}

export default SearchResult
